import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { Note, NoteVisibilities } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { uniq } from 'lodash-es'
import { ReplaySubject, combineLatest } from 'rxjs'
import { map, take, withLatestFrom } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { saveNoteSuccess } from 'src/app/store/deal-view.actions'
import { selectUserEntities } from 'src/app/store/users'
import { NoteFormService } from 'src/components/notes/note-form/note-form.service'
import { getCompanyCategoryOptions } from 'src/components/notes/note-form/note-options'
import { NotesListModule } from 'src/components/notes/notes-list/notes-list.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { NotesService, canEditNote } from 'src/services/data/notes.service'
import { compareBy } from 'src/services/table-utils/compare'
import { SimpleDataSource } from 'src/services/table-utils/data-sources/simple-data-source'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'


@Component({
  selector: 'tc-company-notes-tab',
  templateUrl: './company-notes-tab.component.html',
  styleUrl: './company-notes-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    SelectSearchModule,
    NotesListModule,
    ReactiveFormsModule,
  ]
})
export class CompanyNotesTabComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private readonly Notes: NotesService,
    private readonly NoteForm: NoteFormService,
    private readonly AuthApi: AuthApiService,
    private readonly toaster: ToasterService,
    private readonly store: Store,
    private readonly actions$: Actions,
  ) { super() }

  protected readonly isTrader = this.AuthApi.currentUser.role === 'trader'
  protected readonly isJuniorAdmin = this.AuthApi.currentUser.role === 'junior-administrator'

  readonly categories = this.isTrader ? [{ id: 'general', name: 'General' }] : getCompanyCategoryOptions()

  @Input({ required: true }) accountId: number

  protected readonly filtersForm = new FormGroup({
    search: new FormControl(''),
    user_id: new FormControl<string[]>([]),
    category: new FormControl<string[]>([]),
  })

  private readonly notes$ = new ReplaySubject<DeepReadonly<Note[]>>(1)
  protected readonly users$ = combineLatest([
    this.notes$,
    this.store.pipe(select(selectUserEntities), waitNotEmpty()),
  ]).pipe(map(([notes, users]) => uniq(notes.map(note => users[note.user_id])).sort(compareBy('fullname'))))

  private readonly rows$ = combineLatest([
    this.notes$,
    this.store.pipe(select(selectUserEntities), waitNotEmpty()),
  ]).pipe(map(([notes, users]) => notes.map(note => ({
    note,
    created: note.created,
    category: this.categories[note.attributes.category]?.name,
    visibility: NoteVisibilities[note.visibility]?.name || `${note.visibility}`,
    user: users[note.user_id]?.fullname,
    body: note.body,
    canEdit: canEditNote(note, this.AuthApi.currentUser)
  }))))
  protected readonly dataSource = new SimpleDataSource(this.rows$, this.filtersForm, (rows, filters) => {
    const hasRows = !!rows.length
    if (filters.user_id?.length) rows = rows.filter(r => filters.user_id.includes(r.note.user_id))
    if (filters.category?.length) rows = rows.filter(r => filters.category.includes(r.note.attributes.category))
    if (filters.search) rows = rows.filter(r => JSON.stringify(r).toLowerCase().includes(filters.search.toLowerCase()))
    if (hasRows && !rows.length) this.toaster.warning('No matched records fround!')
    return rows
  })

  ngOnInit() {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
      `${data.note.attributes.ignored}-${data[sortHeaderId]}`

    this.reloadNotes()
    this.actions$.pipe(ofType(saveNoteSuccess), withLatestFrom(this.notes$), untilComponentDestroyed(this)).subscribe(([action, notes]) => {
      const newNotes = action.notes ?? [action.note]
      newNotes.forEach(note => {
        const idx = notes.findIndex(n => n.note_id === note.note_id)
        const freshNotes: DeepReadonly<Note>[] = [...notes]
        if (idx === -1) freshNotes.push(note)
        else freshNotes[idx] = note
        this.notes$.next(freshNotes)
      })
    })
    this.dataSource.refresh$.subscribe(() => this.reloadNotes())
  }

  reloadNotes() {
    let query: any = { account_id: this.accountId }
    if (this.isTrader) query = { ...query, category: ['general', 'logistics', 'product'] }
    this.Notes.queryNotes(query).then(notes => this.notes$.next(notes))
  }

  protected showCreateCompanyNote() {
    this.NoteForm.showCreateCompanyNote(this.accountId, this.categories).then((note) => {
      this.notes$.pipe(take(1)).subscribe(notes => this.notes$.next([...notes, note]))
      this.reloadNotes()
    })
  }

  protected async showUpdateCompanyNote(note: DeepReadonly<Note>) {
    const updated = await this.NoteForm.showUpdateCompanyNote(note)
    if (updated) this.dataSource.refresh()
  }

  protected async ignoreNote(note: DeepReadonly<Note>) {
    await this.Notes.ignoreImmutable(note)
    this.dataSource.refresh()
  }
  protected async unignoreNote(note: DeepReadonly<Note>) {
    await this.Notes.unignoreImmutable(note)
    this.dataSource.refresh()
  }
}
