import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Cost, DEAL_DRAFT, ForexRates, MatchedOffer } from '@tradecafe/types/core';
import { DeepReadonly } from '@tradecafe/types/utils';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { AuthApiService } from 'src/api/auth';
import { CostFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-details/cost-form/cost-form.service';
import { CostsFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema';
import { readCostForm } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service';
import { buildDealCostForm, prepareDealCostPatch } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service-factory';
import { ShipmentRatePickerService } from 'src/pages/admin/trading/deal-form/deal-shipping/shipment-rate-picker/shipment-rate-picker.service';

export interface CostsListDialogOptions {
  title: string
  matchedOffer: DeepReadonly<MatchedOffer>
  costs: DeepReadonly<Partial<Cost>[]>
  filter?: (c: DeepReadonly<Partial<Cost>>) => boolean

  canAddTertiaryCost?: boolean
  canAddFreight?: boolean
  canRemoveCosts?: boolean
}

export interface CostsListDialogResult {
  costs: Partial<Cost>[]
  shouldRemoveCosts: boolean
}


@Component({
  selector: 'tc-costs-list-dialog',
  templateUrl: './costs-list-dialog.component.html',
  styleUrl: './costs-list-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostsListDialogComponent extends OnDestroyMixin {
  constructor(
    private readonly CostForm: CostFormService,
    private readonly ShipmentRatePicker: ShipmentRatePickerService,
    private readonly AuthApi: AuthApiService,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: CostsListDialogOptions,
    private readonly dialogRef: MatDialogRef<CostsListDialogComponent, CostsListDialogResult>,
  ) {
    super()
  }

  // options
  protected readonly canAddTertiaryCost = this.dialogData.canAddTertiaryCost
  protected readonly canAddFreight = this.dialogData.canAddFreight
  protected readonly canRemoveCosts = this.dialogData.canRemoveCosts
  protected readonly title = this.dialogData.title
  protected readonly filter = this.dialogData.filter
  protected readonly matchedOffer = this.dialogData.matchedOffer
  protected readonly fxRates = { rates: this.dialogData.matchedOffer.fx_rate as ForexRates }

  // form
  protected readonly shouldRemoveCosts = new FormControl(false)
  protected readonly costsForm = new FormArray(this.dialogData.costs.map(cost =>
    buildDealCostForm(prepareDealCostPatch(cost)))) as CostsFormGroup

  protected save() {
    this.dialogRef.close({
      costs: this.costsForm.getRawValue().map(cf => readCostForm(cf)),
      shouldRemoveCosts: this.canRemoveCosts ? this.shouldRemoveCosts.value : undefined,
    })
  }

  protected cancel() {
    this.dialogRef.close()
  }


  protected async showAddCost(cost?: Partial<Cost>) {
    const supplier_id = this.matchedOffer.offer.account
    const buyer_id = this.matchedOffer.bid.account
    const costs = await this.CostForm.showCreateCost({ supplier_id, buyer_id, status: DEAL_DRAFT }, cost)
    if (costs?.length) this.createCosts(costs)
  }

  protected async showAddFreight() {
    const cost = await this.ShipmentRatePicker.addFreightCost({
      originLocationId: this.matchedOffer.offer.pickup,
      destLocationId: this.matchedOffer.bid.delivery,
      buyerId: this.matchedOffer.bid.account.toString(),
      weight: {
        amount: this.matchedOffer.offer.weight.amount,
        measure_id: this.matchedOffer.offer.weight.unit,
      },
      matchedOfferId: this.matchedOffer.matched_offer_id,
    })
    if (cost) this.createCosts([cost])
  }

  private createCosts(costs: DeepReadonly<Partial<Cost>[]>) {
    costs = costs.map(c => ({ ...c, creator_id: this.AuthApi.currentUser.user_id }))

    const forms = costs.map(cost => buildDealCostForm(prepareDealCostPatch(cost)))
    forms.forEach(form => this.costsForm.push(form))
  }

  protected updateCost(cost: DeepReadonly<Partial<Cost>>) {
    const i = this.findCostIndex(cost)
    this.costsForm.controls[i].reset(prepareDealCostPatch(cost))
  }

  protected removeCost(cost: DeepReadonly<Partial<Cost>>) {
    const i = this.findCostIndex(cost)
    this.costsForm.removeAt(i)
  }

  private findCostIndex(cost: DeepReadonly<Partial<Cost>>) {
    // NOTE: use cost.ID as a fallback measure for unsaved new costs
    return this.costsForm.value.findIndex(costForm =>
      cost.cost_id && cost.cost_id === costForm?.cost.cost_id ||
      cost.ID && cost.ID === costForm?.cost.ID)
  }
}
